import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Github from '../images/github.svg'
import GithubHover from '../images/githubHover.svg'
import GithubActive from '../images/githubActive.svg'
import Twitter from '../images/twitter.svg'
import TwitterHover from '../images/twitterHover.svg'
import TwitterActive from '../images/twitterActive.svg'
import LinkedIn from '../images/linkedIn.svg'
import LinkedInHover from '../images/linkedInHover.svg'
import LinkedInActive from '../images/linkedInActive.svg'
import stackOverflow from '../images/stackOverflow.svg'
import stackOverflowHover from '../images/stackOverflowHover.svg'
import stackOverflowActive from '../images/stackOverflowActive.svg'

const imageStyles =
  'transition-all duration-500 absolute object-cover top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'
const images = {
  github: {
    default: Github,
    hover: GithubHover,
    active: GithubActive,
  },
  twitter: {
    default: Twitter,
    hover: TwitterHover,
    active: TwitterActive,
  },
  linkedIn: {
    default: LinkedIn,
    hover: LinkedInHover,
    active: LinkedInActive,
  },
  stackOverflow: {
    default: stackOverflow,
    hover: stackOverflowHover,
    active: stackOverflowActive,
  },
}

const SocialIcon = ({ iconName, url }) => (
  <div className="group relative w-14 h-14 flex items-center justify-center">
    <a href={url} target="_blank" rel="noreferrer">
      <img
        className={`opacity-100 group-hover:opacity-0 ${imageStyles}`}
        src={images[iconName].default}
        alt="social icon"
      />

      <img
        className={`opacity-0 group-hover:opacity-100 ${imageStyles}`}
        src={images[iconName].hover}
        alt="social icon"
      />

      <img
        className={`opacity-0 group-active:opacity-100 ${imageStyles}`}
        src={images[iconName].active}
        alt="social icon"
      />
    </a>
  </div>
)

SocialIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default memo(SocialIcon)
