import React from 'react'

export const experienceData = [
  {
    date: '2014/01 - Present',
    company: 'Bliss DDM',
    occupation: 'CTO | Project Manager | Full Stack Developer',
    information:
      'Search of potential clients. I work directly with companies to provide' +
      ' software engineering services. I also manage the development teams.',
  },
  {
    date: '2018 - Present',
    company: 'Bliss DDM',
    occupation: 'CTO | Project Manager',
    project: 'Mi Vet',
    information:
      'Apart from creating reusable components for Bliss DDM custom, SAAS,' +
      ' veterinarian software, I also worked as a Product Owner and Project Manager' +
      ' in collaboration with the CEO',
  },
  {
    date: '2019/06 - 2020/03',
    company: 'Truelogic Software',
    occupation: 'Front-End Tech Lead',
    information:
      "Worked in Photobooker's first MVP releasing a production product ready to be used US wide.",
  },
  {
    date: '2019/04 - 2019/06',
    company: 'eFIN',
    occupation: 'Sr Front-End Engineer',
    information:
      "Worked on a crypto change trading desktop application's front end.",
  },
  {
    date: '2019/02 - 2019/04',
    company: 'Null Unit',
    occupation: 'Sr Front-End Engineer',
    information:
      'Worked directly with founders to build the front-end part of a customized' +
      ' management system for servers, CDN and resources management for Equinix,' +
      ' proposing and implementing new ideas to improve the UX and UI.',
  },
  {
    date: '2018/11 - 2019/02',
    company: 'Manufactured',
    occupation: 'Sr Full-Stack Engineer',
    information:
      'I worked on the startup as a main developer building the next manufacturing management system for brands.',
  },
  {
    date: '2014/07 - 2018/10',
    company: 'iSeatz',
    occupation: 'Senior Front-End Developer',
    project: 'American Express',
    information:
      "Worked on the new, responsive version of the AMEX Travel's Flight, Hotels, Cars and Packages websites.",
  },
  {
    date: '2014/07 - 2018/10',
    company: 'iSeatz',
    occupation: 'Senior Front-End Developer',
    project: 'iSeatz website',
    information: 'Solo developer on the project since 3rd Q 2015',
  },
  {
    date: '2014/08 - 2017/12',
    company: 'Bliss DDM',
    occupation: 'Business Analyst | Full Stack Developer',
    project: 'Casa Cuesta',
    information:
      'I contracted with the company to build a personalized software solution to have their paperwork and manual tasks automatized, handling' +
      ' their suit craft processes covered including inventory, clients, sales, accounting.',
  },
  {
    date: '2013/10 - 2014/07',
    company: 'Adesis Netlife',
    occupation: 'Front End Tech Lead',
    project: 'BBVA Bancomer',
    information:
      'Led PyMES (Small and medium-sized companies) Front End project built from scratch creating a core sdk.',
  },
  {
    date: '2011/12 - 2013/09',
    company: (
      <>
        Ixpan <span className="text-xs align-middle">S.A. de C.V</span>
      </>
    ),
    occupation: 'Mobile and Web Developer',
  },
  {
    date: '2011/03 - 2011/11',
    company: (
      <>
        Softgüer <span className="text-xs align-middle">S.A. de C.V</span>
      </>
    ),
    occupation: 'Java Web Developer',
  },
]
